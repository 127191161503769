<template>
  <div
    class="relative flex min-h-screen flex-col justify-between bg-[#D0F7FF] pt-16 lg:pt-0"
  >
    <div class="fixed top-0 z-50 w-full">
      <AppHeader is-simple />
    </div>

    <div>
      <slot />
    </div>

    <footer>
      <slot name="footer">
        <AppFooter />
      </slot>
    </footer>
  </div>
</template>
